<template>
  <div>
    <!-- 垂直居中 -->
    <div
      style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); border:none"
      @click="goLoginUrl"
      id="aiVerbalTrick"
      v-show="loginUrl"
    >
      <img
        src="@/assets/images/aiVerbalTrick.png"
        alt="点击跳转"
        style="border:none"
        title="点击跳转"
        v-if="loginUrl"
      />
    </div>
    <div
      style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); border:none"
      v-show="!loginUrl"
    >
      <img
        src="@/assets/images/aiVerbalTrick2.png"
        alt=""
        style="border:none"
      />
    </div>
  </div>
</template>

<script>
import { loginMem } from "@/api/aIMarketing";
export default {
  name: "",
  data() {
    return {
      loginUrl: "",
    };
  },
  created() {
    this.getLoginAddress();
  },
  mounted() {
    // 鼠标移上aiVerbalTrick鼠标样式改变
    const aiVerbalTrick = document.getElementById("aiVerbalTrick");
    aiVerbalTrick.onmouseover = function() {
      aiVerbalTrick.style.cursor = "pointer";
    };
    aiVerbalTrick.onmouseout = function() {
      aiVerbalTrick.style.cursor = "default";
    };
  },
  methods: {
    goLoginUrl() {
      // console.log("loginUrl", this.loginUrl);
      if (this.loginUrl) {
        window.open(this.loginUrl, "_blank");
      } else {
        this.$message.error("企业未授权无法操作!");
      }
    },
    async getLoginAddress() {
      const res = await loginMem({});
      if (res.code == 200) {
        // console.log("ai话术", res.data.url);
        this.loginUrl = res.data.url;
      } else if (res.code == 201) {
        // console.log("企业未授权无法操作!", res);
        if (res.message.includes("智云声")) {
          this.$message.error("企业未授权无法操作!");
          return;
        }
        this.$message.error(res.message);
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
